<template>
  <div>
    <ul class="biz-breadcrumb">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><a>{{$t('COMPANY_INFO.TITLE')}}</a></li>
    </ul>
    <div class="page">
      <CompanyBasicView :company="company" :tsr="tsr" :previewMode="false"/>

      <div class="mt-4 card biz-primary">
        <div class="card-header font-weight-bold">
          {{$t('COMPANY_INFO.HEADER_ADDITIONAL_INFO')}}
          <div class="control">
            <router-link to="/supplier/manage/company-addon-info/edit">{{$t('COMMON.EDIT')}}</router-link>
          </div>
        </div>
        <div class="card-body">
          
          <div class="row">
            <div class="col">
              <div class="biz-form-group">
                <label class="biz-form-label font-weight-bold">{{$t('COMPANY_INFO.ESTABLISHMENT_DATE')}}</label>
                <div class="biz-form-body">
                  <p class="biz-offset">{{ company.summary.establishmentDateYear ? company.summary.establishmentDateYear + "年" + company.summary.establishmentDateMonth + "月" : $t('COMMON.NOT_REGISTERED') }}</p>
                  
                </div>
              </div>
            </div>
            <div class="col">
              <div class="biz-form-group">
                <label class="biz-form-label font-weight-bold">{{$t('COMPANY_INFO.CAPITAL')}}</label>
                <div class="biz-form-body">
                  <p class="biz-offset">{{ company.summary.capital | formatCapitalCurrencyView }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-6">
              <div class="biz-form-group">
                <label class="biz-form-label font-weight-bold">{{$t('COMPANY_INFO.EMPLOYEES_COUNT')}}</label>
                <div class="biz-form-body">
                  <p class="biz-offset">{{ company.summary.employeesNumber ? company.summary.employeesNumber + '名' : $t('COMMON.NOT_REGISTERED')}}</p>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="biz-form-group">
                <label class="biz-form-label font-weight-bold">{{$t('COMPANY_INFO.WEBSITE')}}</label>
                <div class="biz-form-body">
                  <p class="biz-offset" :title="company.summary.url">{{company.summary.url | formatLongText}}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <div class="biz-form-group">
                <label class="biz-form-label font-weight-bold">{{$t('COMPANY_INFO.COMPANY_NAME_KATA')}}</label>
                <div class="biz-form-body">
                  <p class="biz-offset">{{company.summary.ceoName || $t('COMMON.NOT_REGISTERED')}}（{{company.summary.ceoNameKana || $t('COMMON.NOT_REGISTERED')}}）</p>
                  <!-- <p class="biz-offset">{{company.summary.ceoBirthYear ? company.summary.ceoBirthYear + $t('COMMON.YEAR') + (company.summary.ceoBirthMonth + 1) + $t('COMMON.MONTH') + company.summary.ceoBirthDay +  $t('COMMON.DAY_OF_MONTH') + $t('COMMON.BORN') : $t('COMMON.NOT_REGISTERED')}}</p> -->
                </div>
              </div>
            </div>
            <div class="col">
              <div class="biz-form-group">
                <label class="biz-form-label font-weight-bold">{{$t('COMPANY_INFO.DIRECTOR_OTHER_THAN_CEO')}}</label>
                <div class="biz-form-body">
                  <p class="biz-offset" v-if="!company.executives">{{$t('COMMON.NOT_REGISTERED')}}</p>
                  <p class="biz-offset" v-else-if="company.executives.length <= 0">{{$t('COMMON.NOT_REGISTERED')}}</p>
                  <div v-for="(ce) in company.executives" v-bind:key="ce.code">
                    <p class="biz-offset">
                      <span>{{ce.name}}</span>
                      <span class="ml-2">({{ce.nameKana}})</span>
                      <span class="ml-2">{{ce.birthYear}}{{$t('COMMON.YEAR')}}{{ce.birthMonth}}{{$t('COMMON.MONTH')}}{{ce.birthDay}}{{$t('COMMON.DAY_OF_MONTH')}}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <div class="biz-form-group">
                <label class="biz-form-label font-weight-bold">{{$t('COMPANY_INFO.FACTORY')}}</label>
                <div class="biz-form-body">
                  <p class="biz-offset" v-if="company.factories.length <= 0">{{$t('COMMON.NOT_REGISTERED')}}</p>
                  <div v-for="factory in company.factories" v-bind:key="factory.id">
                    <p class="biz-offset">{{factory.name || $t('COMMON.NOT_REGISTERED')}}</p>
                    <p class="biz-offset pl-5">{{factory.address || $t('COMMON.NOT_REGISTERED')}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="biz-form-group">
                <label class="biz-form-label font-weight-bold">{{$t('COMPANY_INFO.OFFICES_OTHER_THAN_FACTORY_AND_MAIN_ADDRESS')}}</label>
                <div class="biz-form-body">
                  <p class="biz-offset" v-if="company.offices.length <= 0">{{$t('COMMON.NOT_REGISTERED')}}</p>
                  <div v-for="office in company.offices" v-bind:key="office.id">
                    <p class="biz-offset">{{office.name || $t('COMMON.NOT_REGISTERED')}}</p>
                    <p class="biz-offset pl-5">{{office.address || $t('COMMON.NOT_REGISTERED')}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <div class="biz-form-group">
                <label class="biz-form-label font-weight-bold">{{$t('COMPANY_INFO.MAIN_SALES')}}</label>
                <div class="biz-form-body">
                  <p class="biz-offset" v-if="company.sellers.length <= 0">{{$t('COMMON.NOT_REGISTERED')}}</p>
                  <div v-for="ms in company.sellers" v-bind:key="ms.code">
                    <p class="biz-offset">{{ms.name || $t('COMMON.NOT_REGISTERED')}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="biz-form-group">
                <label class="biz-form-label font-weight-bold">{{$t('COMPANY_INFO.MAIN_SUPPLIERS')}}</label>
                <div class="biz-form-body">
                  <p class="biz-offset" v-if="company.suppliers.length <= 0">{{$t('COMMON.NOT_REGISTERED')}}</p>
                  <div v-for="ms in company.suppliers" v-bind:key="ms.code">
                    <p class="biz-offset">{{ms.name || $t('COMMON.NOT_REGISTERED')}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <div class="biz-form-group">
                <label class="biz-form-label font-weight-bold">{{$t('COMPANY_INFO.MAIN_SHAREHOLDERS')}}</label>
                <div class="biz-form-body">
                  <p class="biz-offset" v-if="company.shareholders.length <= 0">{{$t('COMMON.NOT_REGISTERED')}}</p>
                  <div v-for="ms in company.shareholders" v-bind:key="ms.code">
                    <p class="biz-offset">{{ms.name || $t('COMMON.NOT_REGISTERED')}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="biz-form-group">
                <label class="biz-form-label font-weight-bold">{{$t('COMPANY_INFO.BANKS')}}</label>
                <div class="biz-form-body">
                  <p class="biz-offset" v-if="company.banks.length <= 0">{{$t('COMMON.NOT_REGISTERED')}}</p>
                  <div v-for="ms in company.banks" v-bind:key="ms.code">
                    <p class="biz-offset">{{ms.name || $t('COMMON.NOT_REGISTERED')}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <div class="biz-form-group">
                <label class="biz-form-label font-weight-bold">{{$t('COMPANY_INFO.MAKERS')}}</label>
                <div class="biz-form-body">
                  <p class="biz-offset" v-if="company.manufacturers.length <= 0">{{$t('COMMON.NOT_REGISTERED')}}</p>
                  <div v-for="maker in company.manufacturers" v-bind:key="maker.code">
                    <p class="biz-offset">{{maker.name || $t('COMMON.NOT_REGISTERED')}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
            </div>
          </div>

        </div>
      </div>
      <div class="mt-4 card biz-primary mb-3">
        <div class="card-header font-weight-bold">
          {{$t('COMPANY_INFO.FINANCIAL_INFO')}}
          <div class="control">
            <router-link to="/supplier/manage/company-addon-info/edit#financialInfo">{{$t('COMMON.EDIT')}}</router-link>
          </div>
        </div>
        <div class="card-body d-flex">
          <div class="d-inline-block">
            <p class="font-weight-bold">{{$t('COMPANY_INFO.SETTLEMENT_DATE')}}</p>
            <hr/>
            <p class="font-weight-bold">{{$t('COMPANY_INFO.SALES')}}</p>
            <hr/>
            <p class="font-weight-bold">{{$t('COMPANY_INFO.PROFIT_BEFORE_TAX')}}</p>
            <hr/>
            <p class="font-weight-bold">{{$t('COMPANY_INFO.PROFIT_AFTER_TAX')}}</p>
          </div>
          <div class="d-inline-block" v-for="(fi, index) in company.financialInfos" v-bind:key="index">
            <p class="text-center mx-5">{{fi.year}}{{$t('COMMON.YEAR')}}{{fi.month}}{{$t('COMMON.MONTH')}}</p>
            <hr/>
            <p class="text-right mx-5">{{fi.sales | formatCapitalCurrencyView}}</p>
            <hr/>
            <p class="text-right mx-5">{{fi.ordinaryProfit | formatCapitalCurrencyView}}</p>
            <hr/>
            <p class="text-right mx-5">{{fi.profitAfterTax | formatCapitalCurrencyView}}</p>
          </div>
          <div class="d-inline-block" style="flex:1;">
            <p class="text-center mx-5" style="visibility: hidden;">h</p>
            <hr/>
            <p class="text-right mx-5" style="visibility: hidden;">h</p>
            <hr/>
            <p class="text-right mx-5" style="visibility: hidden;">h</p>
            <hr/>
            <p class="text-right mx-5" style="visibility: hidden;">h</p>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <j-button variant="light" to="/supplier/manage">
          <font-awesome-icon icon="arrow-left"></font-awesome-icon>
          {{$t('COMMON.GO_BACK')}}
        </j-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
<script>
  import CompanyInfoModel from '@/model/CompanyInfo';
  import CompanyBasicView from '@/components/common/CompanyBasicView.vue';
  
  export default {
    components: { CompanyBasicView  },
    data: () => ({
      company: {
        basic:          {},
        summary:        {},
        industries:     [],
        factories:      [],
        offices:        [],
        sellers:        [],
        suppliers:      [],
        shareholders:   [],
        banks:          [],
        manufacturers:  [],
        financialInfos: []
      },
      tsr: {}
    }),
    mounted(){
      Promise.all([
        CompanyInfoModel.getSummary(this.$store.state.user.companyCode),
      ]).then((results) => {
        this.company = results[0].data;
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    }
  }
</script>